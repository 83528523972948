import { fork, all } from 'redux-saga/effects';
import { visibilitySaga } from '@biotmed/base-components';
import { saga as authSaga } from '@biotmed/auth-pages';
import { combinedCrudSagas } from '@biotmed/data-components';
import { systemNotificationsSaga } from '@biotmed/system-notifications';
import { sagas as dataSaga } from '../data';

/*
 * The entry point for all general sagas used in this application.
 */
export default function* root() {
  yield all([
    fork(dataSaga),
    fork(authSaga),
    fork(combinedCrudSagas),
    fork(systemNotificationsSaga),
    fork(visibilitySaga),
  ]);
}
