import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import AppConfig from '../config/AppConfig';

interface AppBrandingHandlerProps {}

export const AppBrandingHandler: React.FC<AppBrandingHandlerProps> = props => {
  const intl = useIntl();
  const updateApplicationTitle = () => {
    const titleDoc = document.getElementById('applicationTitle');
    if (titleDoc) {
      titleDoc.innerHTML = intl.formatMessage({
        id: 'application.branding.title',
        defaultMessage: 'BioT Organization Portal',
      });
    }
  };

  const updateApplicationFavicon = () => {
    const favicon32 = document.getElementById('application32BitIcon') as HTMLAnchorElement | null;
    if (favicon32) {
      if (AppConfig.BRANDING_FAVICON_32) {
        favicon32.href = AppConfig.BRANDING_FAVICON_32;
      } else {
        favicon32.href = `${AppConfig.PUBLIC_URL}/favicon-32.png`;
      }
    }

    const favicon256 = document.getElementById('application256BitIcon') as HTMLAnchorElement | null;
    if (favicon256) {
      if (AppConfig.BRANDING_FAVICON_APPLE_TOUCH_256) {
        favicon256.href = AppConfig.BRANDING_FAVICON_APPLE_TOUCH_256;
      } else {
        favicon256.href = `${AppConfig.PUBLIC_URL}/favicon-apple-touch-256.png`;
      }
    }
  };

  useEffect(() => {
    updateApplicationTitle();
    updateApplicationFavicon();
  }, []);

  return null;
};
