import React from 'react';
import { IFrame, IFrameMessage, IFrameProps } from '@biotmed/data-components';

export interface BioTMessagesRequiredPayload {
  ON_IFRAME_LOADED: undefined;
}

export interface BioTIFrameMessage
  extends IFrameMessage<
    keyof BioTMessagesRequiredPayload,
    BioTMessagesRequiredPayload[keyof BioTMessagesRequiredPayload]
  > {}

export const MainViewIFrame = <T,>(props: IFrameProps<T>) => {
  const { ...restProps } = props;

  const IFrameAdditionalActions = {};

  return <IFrame additionalActions={IFrameAdditionalActions} {...restProps} />;
};
