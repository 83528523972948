import styled from 'styled-components';

export const AppName = styled.div`
  font-size: 1.8rem;
  line-height: 2.9rem;
  font-family: 'Poppins';
`;

export const LogoContainer = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 5px;
  gap: 8px;
  color: #000;
`;
