// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ConnectionAlert, GlobalError } from '@biotmed/base-components';
import { ErrorBoundary } from 'react-error-boundary';
import { MainContent, Footer, Header, StyledLayout } from './AppLayout.styled';
import TopMenu from './TopMenu';
import AppFooter from '../../AppFooter';

interface AppLayoutProps {}

const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = ({ children }) => {
  return (
    <StyledLayout>
      <ErrorBoundary FallbackComponent={props => <GlobalError {...props} />}>
        <Header>
          <TopMenu />
        </Header>
        <MainContent>
          <ConnectionAlert />
          {children}
        </MainContent>
      </ErrorBoundary>

      <Footer>
        <AppFooter />
      </Footer>
    </StyledLayout>
  );
};

export default AppLayout;
