import React from 'react';
import { AuthRoutes, LoginResponse } from '@biotmed/auth-pages';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LocalePicker } from '@biotmed/i18n';
import { actions as loginActions } from 'src/redux/data/login/modules/slice';
import appConfig from 'src/config/AppConfig';
import { useNavigate } from 'react-router-dom';
import AppFooter from '../../components/AppFooter';
import OrganizationImage from '../../components/OrganizationImage';

export type { LoginResponse };

export default () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onLoginSuccess = (loginResponse: LoginResponse) => {
    dispatch(loginActions.loginSuccess({ loginResponse }));
  };

  const navigate = useNavigate();

  return (
    <AuthRoutes
      baseUrl={appConfig.API_URL || ''}
      onLoginSuccess={onLoginSuccess}
      companyDetails={{
        name: 'BioT Organization Portal',
        description: intl.formatMessage({
          id: 'companyDetails.label',
          defaultMessage: 'Connect. Collaborate. Care.',
        }),
        Logo: OrganizationImage,
      }}
      onTermsClick={() => {
        window.open(appConfig.TERMS, '_blank');
      }}
      onPrivacyClick={() => {
        window.open(appConfig.PRIVACY, '_blank');
      }}
      onLogoClick={() => {
        navigate('/', { replace: true });
      }}
      renderHeader={() => <LocalePicker />}
      renderFooter={() => <AppFooter />}
    />
  );
};
