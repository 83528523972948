/* eslint-disable no-console */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { LocaleProvider as SharedLocaleProvider } from '@biotmed/i18n';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import APP_CONFIG from 'src/config/AppConfig';
import { actions } from 'src/redux/data/app';
import { useQuery } from '@tanstack/react-query';

interface LocaleProviderProps {
  children: React.ReactNode;
}

const getMessages = async (availableLocales: string[]) => {
  const messagesArray = await Promise.all(
    availableLocales.map(async (language: string) => {
      let localTranslationFile = {};
      let externalTranslationFile = {};

      try {
        localTranslationFile = require(`../../../translations/locales/${language.toString()}.json`);
      } catch (ex) {
        console.error('Failed to get local translation file for', language);
      }

      if (APP_CONFIG.TRANSLATION_FILES_BASE_URL) {
        try {
          externalTranslationFile = await (
            await fetch(`${APP_CONFIG.TRANSLATION_FILES_BASE_URL}/${language.toString()}.json`)
          ).json();
        } catch (ex) {
          console.error('Failed to fetch external translation file for', language);
        }
      }

      const languageMessages = { ...localTranslationFile, ...externalTranslationFile };

      return { language, languageMessages };
    }),
  );

  const messages: { [x: string]: { [x: string]: string } } = await messagesArray.reduce(
    (tempMessages, languageMessages) => {
      return { ...tempMessages, [languageMessages.language]: languageMessages.languageMessages };
    },
    {},
  );

  return messages;
};

export const LocaleProvider: React.FC<LocaleProviderProps> = props => {
  const { children } = props;
  const dispatch = useDispatch();

  const { data: locales } = useQuery({
    queryKey: ['locales'],
    queryFn: () => {
      return getSdkApi()
        .settings.localeApi.getLocales()
        .then(res => res.data);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const availableLocales = useMemo(
    () =>
      locales?.availableLocales
        ? Array.from(locales?.availableLocales)
            ?.filter(locale => !locale.hidden)
            .map(locale => locale.code)
        : [],
    [locales],
  );

  const defaultLocale = locales?.defaultLocaleCode;

  const { data: messages } = useQuery({
    enabled: !!locales,
    queryKey: ['locales', 'messages', availableLocales],
    queryFn: () => {
      return getMessages(availableLocales);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (messages) {
      dispatch(actions.onLocaleSetupSuccess());
    }
  }, [messages]);

  return messages && defaultLocale ? (
    <SharedLocaleProvider
      availableLocales={availableLocales}
      languageToMessages={messages}
      defaultLocale={defaultLocale}
    >
      {children}
    </SharedLocaleProvider>
  ) : null;
};

export default LocaleProvider;
