import { IconButtonMenu, otherIcons, Tabs, VerticalLine } from '@biotmed/base-components';
import { EntityType, SelfProfile, usePortalBuilder } from '@biotmed/data-components';
import { LocalePicker } from '@biotmed/i18n';
import { useFeature } from '@biotmed/react-flags-lib';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppLogo from 'src/components/AppLogo';
import routes from 'src/routes/Navigation/modules/routes';
import { getDynamicTabs } from 'src/utils/dynamicTabs';
import { useTheme } from 'styled-components';
import { selectors as appSelectors } from '../../../redux/data/app';
import { actions as loginActions } from '../../../redux/data/login';
import { selectors } from '../../../redux/data/user';
import OrganizationImage from '../../OrganizationImage';
import { RightContent, StyledIconMenuItem, StyledLink, TopMenu as TopMenuStyled } from './AppLayout.styled';

interface TopMenuProps {}

const getCurrentTab = (tabsData: { tabKey: string }[], currentLocation: string) => {
  const tabKeys = tabsData.map(tabData => tabData.tabKey);

  if (currentLocation && !tabKeys.includes(currentLocation)) {
    return false;
  }

  return currentLocation;
};

const TopMenu: React.FC<TopMenuProps> = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector(selectors.selectFullName);
  const userRole = useSelector(selectors.selectUserRole);
  const userOwnerOrganization = useSelector(selectors.selectOwnerOrganization);
  const userType = useSelector(selectors.selectUserType);
  const { updatedBuilder } = usePortalBuilder({
    selector: appSelectors.selectMainViewBuilder,
    enabled: true,
  });

  const { isFeatureActive } = useFeature({ flag: 'DATA_EXPORT_PAGE' });

  const withDataExportPage = isFeatureActive
    ? [
        {
          title: intl.formatMessage({
            id: 'app-layout.top-menu.link.data-export',
            defaultMessage: 'Data Export',
          }),
          onClick: () => navigate(routes.DATA_EXPORT, { state: routes.DATA_EXPORT }),
        },
      ]
    : [];

  const operationalPages = [
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.nursing-station',
        defaultMessage: 'Nursing Station',
      }),
      tabKey: routes.NURSING_STATION,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.device-operation-center',
        defaultMessage: 'Device Operation Center ',
      }),
      tabKey: routes.DEVICE_OPERATION_CENTER,
    },
  ];
  const handleCallToRouter = (event: React.ChangeEvent<any>, value: string) => {
    navigate(value, { state: value });
  };

  const onDisconnectClick = () => {
    dispatch(loginActions.logout());
  };

  const onChangePasswordClick = () => {
    navigate(routes.CHANGE_PASSWORD, { state: routes.CHANGE_PASSWORD });
  };

  const tabsData = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'app-layout.top-menu.tab.patients',
          defaultMessage: 'Patients',
        }),
        tabKey: routes.PATIENTS,
      },
      {
        label: intl.formatMessage({
          id: 'app-layout.top-menu.tab.caregivers',
          defaultMessage: 'Caregivers',
        }),
        tabKey: routes.CAREGIVERS,
      },
      {
        label: intl.formatMessage({
          id: 'app-layout.top-menu.tab.organization-users',
          defaultMessage: 'Organization Users',
        }),
        tabKey: routes.ORGANIZATION_USERS,
      },
      {
        label: intl.formatMessage({
          id: 'app-layout.top-menu.tab.devices',
          defaultMessage: 'Devices',
        }),
        tabKey: routes.DEVICES,
      },
      ...operationalPages,
      ...getDynamicTabs(updatedBuilder?.tabs),
    ],
    [updatedBuilder],
  );

  const location = useLocation();
  const currentTab = getCurrentTab(tabsData, location?.state);
  const { settings: SettingsIcon } = otherIcons;

  const rightContent = (
    <RightContent>
      <VerticalLine />
      <IconButtonMenu
        menuItems={[...withDataExportPage]}
        Icon={(props: any) => {
          return <SettingsIcon {...props} color={theme.palette.grayScale.darker2} />;
        }}
        renderItem={(onClick: () => void, title: string) => (
          <StyledIconMenuItem key={title} onClick={onClick}>
            {title}
          </StyledIconMenuItem>
        )}
      />
      <LocalePicker />
      <SelfProfile
        orgIcon={OrganizationImage}
        userName={userName}
        userRole={userRole}
        userOwnerOrganization={userOwnerOrganization?.name}
        onDisconnectClick={onDisconnectClick}
        userType={userType as EntityType}
        buttons={[
          {
            text: intl.formatMessage({
              id: 'self-profile.change-password',
              defaultMessage: 'Change Password',
            }),
            onClick: onChangePasswordClick,
          },
        ]}
      />
    </RightContent>
  );

  const leftContent = (
    <StyledLink to="./">
      <AppLogo />
    </StyledLink>
  );

  return (
    <TopMenuStyled>
      <Tabs
        tabBarExtraContent={{
          left: leftContent,
          right: rightContent,
          spaceBetween: 80,
        }}
        textColorActive={theme.palette.primary.medium}
        indicatorOverAll
        tabsData={tabsData}
        selectedTabKey={currentTab}
        padding="0 21px"
        onChange={handleCallToRouter}
        variant="scrollable"
        scrollButtons
      />
    </TopMenuStyled>
  );
};
export default TopMenu;
