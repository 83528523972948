import {
  NursingStation,
  nursingStationReducer,
  nursingStationSaga,
  nursingStationStateKey,
  nursingStationGetInitialState,
} from '@biotmed/data-components';

export { nursingStationReducer, nursingStationSaga, nursingStationStateKey, nursingStationGetInitialState };

export default NursingStation;
