/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum RoutesEnum {
  AUTH = 'auth',
  PATIENTS = 'patients',
  CAREGIVERS = 'caregivers',
  ORGANIZATION_USERS = 'organization-users',
  DEVICES = 'devices',
  NURSING_STATION = 'nursing-station',
  DEVICE_OPERATION_CENTER = 'device-operation-center',
  MEASUREMENTS = 'measurements',
  CHANGE_PASSWORD = 'change-password',
  DATA_EXPORT = 'data-export',
}

export default RoutesEnum;
