import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Snackbar } from '@biotmed/base-components';
import { actions as appActions, selectors as appSelectors } from '../../../redux/data/app/modules/slice';
import { selectors as loginSelectors } from '../../../redux/data/login/modules/slice';
import { PublicPage, PrivatePage } from './Routes';
import { Spin, SpinBox } from './styled';
import RoutesEnum from '../modules/routes';

export interface MatchParams {
  language: string;
}

interface NavigationProps {
  isLoggedIn?: boolean;
  isAppLoading?: boolean;
}

const Navigation: React.FC<NavigationProps> = props => {
  const dispatch = useDispatch();
  const isLoggedIn = !!useSelector(loginSelectors.getIsLoggedIn);
  const isAppLoading = useSelector(appSelectors.getIsAppLoading);

  const _onAppStart = () => {
    dispatch(appActions.appStart());
  };

  // eslint-disable-next-line
  useEffect(_onAppStart, []);

  return (
    <>
      {isAppLoading ? (
        <SpinBox>
          <Spin />
        </SpinBox>
      ) : (
        <Snackbar>
          <Routes>
            <Route caseSensitive path={`/${RoutesEnum.AUTH}/*`} element={<PublicPage isLoggedIn={isLoggedIn} />} />
            <Route path="/*" element={<PrivatePage isLoggedIn={isLoggedIn} />} />
          </Routes>
        </Snackbar>
      )}
    </>
  );
};

Navigation.defaultProps = {
  isLoggedIn: false,
  isAppLoading: false,
};

export default Navigation;
