import { GetViewWithFullInfoResponse } from '@biotmed/settings-sdk';
import { createSlice, createSelector, createAction, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'app';

interface AppState {
  isAppLoading: boolean;
  localeSetupFinished: boolean;
  mainViewBuilder?: GetViewWithFullInfoResponse;
  biotVersion?: string;
}
export const getInitialState = (state?: AppState): AppState => {
  return {
    // eslint-disable-next-line no-use-before-define
    isAppLoading: state ? state.isAppLoading : true,
    localeSetupFinished: state ? state.localeSetupFinished : false,
    mainViewBuilder: state ? state.mainViewBuilder : undefined,
    biotVersion: state ? state.biotVersion : undefined,
  };
};

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();
const getIsAppLoading = createSelector(getState, state => state.isAppLoading);
const selectLocaleSetupFinished = createSelector(getState, state => state.localeSetupFinished);
const selectMainViewBuilder = createSelector(getState, state => state.mainViewBuilder);
const selectBiotVersion = createSelector(getState, state => state.biotVersion);

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    appStartFinish: (state, action) => {
      state.isAppLoading = action.payload;
      return state;
    },
    onLocaleSetupSuccess: state => {
      state.localeSetupFinished = true;
      return state;
    },
    onMainViewBuilderFetch: (state, action: PayloadAction<GetViewWithFullInfoResponse>) => {
      state.mainViewBuilder = action.payload;
      return state;
    },
    onBiotVersionFetch: (state, action) => {
      state.biotVersion = action.payload;
      return state;
    },
  },
});
/* eslint-enable no-param-reassign */

// For saga/reducer-less actions
const extraActions = {
  appStart: createAction(`${STATE_KEY}/appStart`),
};

export const selectors = {
  getIsAppLoading,
  selectLocaleSetupFinished,
  selectMainViewBuilder,
  selectBiotVersion,
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
