import { combineReducers } from 'redux';
import { fork, all } from 'redux-saga/effects';
import { DATA_STATE_KEY } from './constants';
import {
  STATE_KEY as userStateKey,
  reducer as userReducer,
  getInitialState as getUserInitialState,
  saga as userSaga,
} from './user';
import {
  STATE_KEY as appStateKey,
  reducer as appReducer,
  saga as appSaga,
  getInitialState as getAppInitialState,
} from './app';
import {
  STATE_KEY as loginStateKey,
  reducer as loginReducer,
  saga as loginSaga,
  getInitialState as getLoginInitialState,
} from './login';

export const STATE_KEY = DATA_STATE_KEY;

export const getDataInitialState = (state?: any) => ({
  [appStateKey]: getAppInitialState(state?.[appStateKey]),
  [userStateKey]: getUserInitialState(state[userStateKey]),
  [loginStateKey]: getLoginInitialState(state[loginStateKey]),
});

export const reducer = combineReducers({
  [appStateKey]: appReducer,
  [userStateKey]: userReducer,
  [loginStateKey]: loginReducer,
});

export const sagas = function* root() {
  yield all([fork(appSaga), fork(loginSaga), fork(userSaga) /* , fork(measurementsSaga) */]);
};
