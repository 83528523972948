// eslint-disable-next-line no-use-before-define
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';
import AppLayout from 'src/components/AppLayout';
import {
  templatesAdapterByOwnerOrganizationIdFactory,
  DataExportPage,
  usePortalBuilder,
} from '@biotmed/data-components';
import { FilterV2 } from '@biotmed/sdk-api-provider/lib/types/device';
import { getDynamicRoutes } from 'src/utils/dynamicTabs';
import { selectors } from '../../../redux/data/user';
import AuthRoutes from '../../Auth';
import RoutesEnum from '../modules/routes';
import Patients from '../../Patients';
import OrganizationUsers from '../../OrganizationUsers';
import Caregivers from '../../Caregivers';
import ChangePasswordPage from '../../ChangePasswordPage/ChangePasswordPage';
import Devices from '../../Devices';
import NursingStation from '../../NursingStation';
import { Spin, SpinBox } from './styled';
import { selectors as appSelectors } from '../../../redux/data/app';
import DeviceOperation from '../../DeviceOperation';

interface RoutePagesProps {
  isLoggedIn: boolean;
}

export const PublicPage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;

  return !isLoggedIn ? <AuthRoutes /> : <Navigate to="/" replace />;
};

export const PrivatePage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;

  const { updatedBuilder } = usePortalBuilder({
    selector: appSelectors.selectMainViewBuilder,
    enabled: true,
  });

  const ownerOrganization = useSelector(selectors.selectOwnerOrganization);
  const syntheticOwnerOrganization = useMemo(
    // Here we destruct the object because otherwise when cleaning the object from empty strings, there is a problem
    () => (ownerOrganization ? { _ownerOrganization: { ...ownerOrganization } } : {}),
    [ownerOrganization],
  );

  const templatesAdapter = useMemo(() => {
    return ownerOrganization?.id ? templatesAdapterByOwnerOrganizationIdFactory(ownerOrganization.id) : undefined;
  }, [ownerOrganization]);

  const narrowDownFilters = useMemo(() => {
    return ownerOrganization?.id
      ? ({
          '_ownerOrganization.id': {
            in: [ownerOrganization.id],
          },
        } as Record<string, FilterV2>)
      : undefined;
  }, [ownerOrganization]);

  return isLoggedIn ? (
    <AppLayout>
      {!ownerOrganization ? (
        // TODO: Remove this loader when the backend supports sending ownerOrganization with the user object
        <SpinBox>
          <Spin />
        </SpinBox>
      ) : (
        <Routes>
          <Route
            caseSensitive
            path={`/${RoutesEnum.PATIENTS}`}
            element={
              <Patients
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />
          <Route
            caseSensitive
            path={`/${RoutesEnum.CAREGIVERS}`}
            element={
              <Caregivers
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />
          <Route
            caseSensitive
            path={`/${RoutesEnum.ORGANIZATION_USERS}`}
            element={
              <OrganizationUsers
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />
          <Route
            caseSensitive
            path={`/${RoutesEnum.DEVICES}`}
            element={
              <Devices
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />
          <Route
            caseSensitive
            path={`/${RoutesEnum.NURSING_STATION}`}
            element={
              <NursingStation
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />
          <Route
            caseSensitive
            path={`/${RoutesEnum.DEVICE_OPERATION_CENTER}`}
            element={
              <DeviceOperation
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />
          {getDynamicRoutes(updatedBuilder?.tabs)?.map(tab => (
            <Route
              key={tab.path}
              caseSensitive
              path={tab.path}
              element={tab.render({
                narrowDownFilters,
                additionalCreationData: {
                  initialValues: { ...syntheticOwnerOrganization },
                },
              })}
            />
          ))}
          <Route caseSensitive path={`/${RoutesEnum.CHANGE_PASSWORD}`} element={<ChangePasswordPage />} />
          <Route caseSensitive path={`/${RoutesEnum.DATA_EXPORT}`} element={<DataExportPage />} />
          <Route path="*" element={<Navigate to={`/${RoutesEnum.PATIENTS}`} replace />} />
        </Routes>
      )}
    </AppLayout>
  ) : (
    <Navigate to={{ pathname: `/${RoutesEnum.AUTH}` }} replace />
  );
};
