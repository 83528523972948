import {
  DeviceOperation,
  deviceOperationReducer,
  deviceOperationSaga,
  deviceOperationStateKey,
  deviceOperationGetInitialState,
} from '@biotmed/data-components';

export { deviceOperationReducer, deviceOperationSaga, deviceOperationStateKey, deviceOperationGetInitialState };

export default DeviceOperation;
